import { Button } from "@mui/material";
import React from "react";
import { PageSizes, PDFDocument, rgb } from "pdf-lib";
import eleme_sebf_png from "../../../../files/pdfBackgrounds/2024/05_fn_sebf.png";
import gilroyFont from "../../../../files/fonts/Gilroy-Bold.ttf";

import fontkit from "@pdf-lib/fontkit";
export const FinalSebfDocument2024 = (props) => {
  const onDownload = async () => {
    const pdfDoc = await PDFDocument.create();
    pdfDoc.registerFontkit(fontkit);
    const page = pdfDoc.insertPage(0, PageSizes.A4);

    const pngUrl = eleme_sebf_png;
    const pngImageBytes = await fetch(pngUrl).then((res) => res.arrayBuffer());

    const fontBytes = await fetch(gilroyFont).then(
      (res) => res.arrayBuffer() //Fontu da pdf içine gömebilmek için byte array'e dönüştürüyoruz.
    );
    const customFont = await pdfDoc.embedFont(fontBytes);
    const pngImage = await pdfDoc.embedPng(pngImageBytes);

    const pngDims = pngImage.scale(0.5);
    page.drawImage(pngImage, {
      x: 0,
      y: 0,
      width: pngDims.width,
      height: pngDims.height,
    });

    page.drawText(props.userName, {
      x: 180,
      y: 710,
      size: 11,
      color: rgb(0, 0, 0),
      font: customFont,
      lineHeight: 22,
    });

    const fileNumber = "TZV-OYUN24/" + props.fileNumber;
    page.drawText(fileNumber, {
      x: 425,
      y: 518,
      size: 9,
      color: rgb(0, 0, 0),
      font: customFont,
      lineHeight: 22,
    });

    page.drawText(fileNumber, {
      x: 322,
      y: 198,
      size: 8,
      color: rgb(0, 0, 0),
      font: customFont,
      lineHeight: 22,
    });

    const pdfBytes = await pdfDoc.save();

    var blob = new Blob([pdfBytes], { type: "application/pdf" });
    var link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    var fileName = props.userName + " (SEBF)";
    link.download = fileName;
    link.click();
  };

  return (
    <Button
      title="İlkokul, ortaokul ve lise öğrencileri için MEB sistemine yüklenmek üzere hazırlanmış Sosyal Etkinlik Bilgilendirme Formu."
      variant="contained"
      style={{
        width: 180,
        textTransform: "none",
        alignSelf: "center",
        marginTop: 10,
      }}
      onClick={onDownload}
    >
      2024 Final SEBF İndir
    </Button>
  );
};
