import AppContext from "../../AppContext";
import "./profilPage.css";
import {
  Button,
  MenuItem,
  Modal,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React, { useContext, useEffect, useRef, useState } from "react";
import {
  getPayment,
  fetchActiveProduct,
  getPaymentUrl,
  markPaymentAsSuccessful,
  pullUserData,
  saveUserData,
  sendVerificationEmail,
  reloadAuthData,
  getPublicUrlOfFile,
  fetchFileFromUrl,
  uploadImageToDb,
  addAboutToDb,
  getHallOfFameUsers,
  getHallOfFameUser,
  writeTokenToDb,
  getConcurrentLoginToken,
  fbLogout,
} from "../../db/firebaseMethods";
import { Link, Navigate } from "react-router-dom";
import {
  PAYMENT_HOST,
  YEARS,
  HALICI_PAYMENT_HOST,
  CITIES,
  EDUCATIONS,
  OCCUPATIONS,
} from "../../common/constants";
import EditIcon from "@mui/icons-material/Edit";
import LockIcon from "@mui/icons-material/Lock";
import HistoryIcon from "@mui/icons-material/History";
import LoadingBlock from "../../components/organisms/loadingBlock/loadingBlock";
import Grid from "@mui/material/Grid";
import { PageSizes, PDFDocument, StandardFonts, rgb } from "pdf-lib";
import gilroyFont from "../../files/fonts/Gilroy-Bold.ttf";

import fontkit from "@pdf-lib/fontkit";
import { ElemeSebfDocument2023 } from "../../components/organisms/pdfs/2023/04_elemeSebfDocument";
import { YFSuccessDocument2023 } from "../../components/organisms/pdfs/2023/yf_successDocument";
import { FinalSebfDocument2023 } from "../../components/organisms/pdfs/2023/05_finalSebfDocument";
import Cropper from "react-easy-crop";
import { getCroppedImg } from "../../utils/canvasUtils";

export default function ProfilePage() {
  const { setCentralState, user, activeProduct, refreshUserData } =
    React.useContext(AppContext);

  if (!user) {
    return null;
  }
  // console.log({ user });
  // console.log(activeProduct);
  if (!user.emailVerified) return GenerateEmailVerificationBox();

  return (
    <div className="profilePageContainer">
      <h3 className="pageHeader">YARIŞMA SAYFASI</h3>
      {/* <h3>
        OYUN 2023 Yarışmasının kayıt ve başvuruları 1 Haziran 2023 Perşembe günü
        başlayacaktır. Aşağıda geçen yıla ait sınav ve belgelerinizi
        görüntüleyebilirsiniz.
      </h3> */}
      {GeneratePersonalInfoBox()}

      {user?.products?.[activeProduct?.id]?.final_derecesi <= 10 &&
        hallOfFameInputBox(user, activeProduct)}
      {GenerateActiveProductBox()}
    </div>
  );
}

const GenerateEmailVerificationBox = () => {
  const { user, setCentralState } = React.useContext(AppContext);

  const checkForVerificationStatus = async () => {
    // Tekrar link göndermeden önce bi kez daha kontrol edelim, belki başka sekmeden doğrulama yapılmıştır

    await reloadAuthData(user, setCentralState);
    // await new Promise(resolve => setTimeout(resolve, 3000)); // 3 sec
    // console.log("user after 3 seconds", user);
    // if(!user.emailVerified){
    //     console.log("user in re-verify buton after refresh:", user);
    //     // await sendVerificationEmail();
    //     alert("Doğrulama linki e-posta adresinize gönderildi. Lütfen kontrol ediniz.");
    // }
    // else{
    //     alert("Doğrulama başarılı. Profil sayfanıza yönlendirileceksiniz.");
    // }
  };

  return (
    <div className="verificationBox">
      <p>
        {" "}
        {user.email + " e-posta adresinize bir doğrulama linki gönderdik."}
      </p>
      <p>
        Sınavlara erişebilmek için lütfen e-posta kutunuza giderek size
        gönderilen doğrulama linkine tıklayınız.
      </p>

      <div className="verificationbuttonDiv">
        <Button
          variant="contained"
          onClick={() => checkForVerificationStatus()}
        >
          Doğrulama Linkini Tekrar Gönder
        </Button>
      </div>
    </div>
  );
};

const GeneratePersonalInfoBox = () => {
  const { refreshUserData, setCentralState, user, activeProduct } =
    React.useContext(AppContext);
  const [editModeEnabled, setEditMode] = useState(false);
  const [newName, setName] = useState(user?.name || "");
  const [newPhone, setPhone] = useState(user?.phone || "");
  const [gender, setGender] = useState(user?.gender || "");
  const [city, setCity] = useState(user?.city || "");
  const [education, setEducation] = useState(user?.education || "");
  const [occupation, setOccupation] = useState(user?.occupation || "");
  const [carPlate, setCarPlate] = useState(user?.car_plate || "");
  const [newBirthYear, setBirthYear] = useState(user?.birth_year || 0);
  const [isLoading, setLoading] = useState(false);

  const isAllCompulsaryFieldsFilled =
    user &&
    user.name &&
    user.birth_year &&
    user.gender &&
    user.city &&
    user.education &&
    user.occupation;
  user.car_plate;

  useEffect(() => {
    if (user && !user.name) refreshUserData();
  }, [user]);

  if (!user) return null;

  const saveProfileData = async (id, data) => {
    let missingFields = [];
    [
      newName,
      newPhone,
      gender,
      city,
      education,
      occupation,
      newBirthYear,
    ].forEach((f) => {
      if (!f) missingFields.push(f);
    });

    if (missingFields.length > 0) {
      alert("Lütfen tüm bilgileri doldurunuz!");
    } else {
      setLoading(true);
      await saveUserData(id, data);
      await refreshUserData();
      setLoading(false);
      setEditMode(false);
    }
  };

  if (editModeEnabled || !isAllCompulsaryFieldsFilled) {
    return (
      <div className="personelInfoBox">
        {isLoading && <LoadingBlock />}

        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={4}>
            <TextField
              id="newName"
              label="Adı-Soyadı"
              name="newName"
              disabled
              defaultValue={newName}
              value={newName}
              onChange={(e) => setName(e.target.value)}
              autoComplete={"disabled"}
              style={{ width: 250 }}
              size="small"
              error={newName.length < 3}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <TextField
              id="newPhone"
              label="Telefon"
              name="newPhone"
              defaultValue={newPhone}
              value={newPhone}
              onChange={(e) => setPhone(e.target.value)}
              autoComplete={"disabled"}
              style={{ width: 250 }}
              size="small"
              error={newPhone.length < 10}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <TextField
              select
              id="yearChooser"
              defaultValue={user.birthYear}
              value={newBirthYear}
              onChange={(e) => setBirthYear(e.target.value)}
              label="Doğum Yılı"
              style={{ width: 250 }}
              className="yearSelector"
              size="small"
              error={!newBirthYear}
              disabled
              // disabled={
              //   activeProduct.order_id !== user.registration_season &&
              //   !!user.birth_year
              // }
            >
              {YEARS.map((y) => (
                <MenuItem key={y + "yil_menu"} value={y}>
                  {y}
                </MenuItem>
              ))}
            </TextField>
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <TextField
              select
              id="genderChooser"
              error={!gender}
              value={gender}
              onChange={(e) => setGender(e.target.value)}
              label="Cinsiyet"
              style={{ width: 250 }}
              className="yearSelector"
              size="small"
            >
              <MenuItem value="Kadın">Kadın</MenuItem>
              <MenuItem value="Erkek">Erkek</MenuItem>
            </TextField>
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <TextField
              select
              value={city}
              onChange={(e) => setCity(e.target.value)}
              label="Şehir"
              style={{ width: 250 }}
              className="yearSelector"
              size="small"
              error={!city}
            >
              {CITIES.map((c) => (
                <MenuItem key={c + "city_menu"} value={c}>
                  {c}
                </MenuItem>
              ))}
            </TextField>
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <TextField
              error={!education}
              select
              value={education}
              onChange={(e) => setEducation(e.target.value)}
              label="Eğitim Durumu"
              style={{ width: 250 }}
              className="yearSelector"
              size="small"
            >
              {EDUCATIONS.map((c) => (
                <MenuItem key={c + "education_menu"} value={c}>
                  {c}
                </MenuItem>
              ))}
            </TextField>
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <TextField
              select
              value={occupation}
              onChange={(e) => setOccupation(e.target.value)}
              label="Meslek"
              style={{ width: 250 }}
              className="yearSelector"
              size="small"
              error={!occupation}
            >
              {OCCUPATIONS.map((c) => (
                <MenuItem key={c + "occupation_menu"} value={c}>
                  {c}
                </MenuItem>
              ))}
            </TextField>
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <TextField
              id="carPlate"
              label="Araç Plakası (Final İçin)"
              placeholder="Finale Gelecekler İçindir"
              name="carPlate"
              defaultValue={carPlate}
              value={carPlate}
              onChange={(e) => setCarPlate(e.target.value)}
              autoComplete={"disabled"}
              style={{ width: 250 }}
              size="small"
            />
          </Grid>

          <Grid item xs={12}>
            <div className="profileButtonsContainer">
              <div className="profileButtons">
                <Button
                  variant="outlined"
                  onClick={() => {
                    setEditMode(false);
                  }}
                >
                  Vazgeç
                </Button>
                <Button
                  variant="outlined"
                  color="secondary"
                  onClick={() => {
                    saveProfileData(user.uid, {
                      name: newName.trim(),
                      birth_year: newBirthYear,
                      email: user.email,
                      phone: newPhone,
                      gender,
                      city,
                      education,
                      occupation,
                      car_plate: carPlate,
                    });
                  }}
                >
                  Kaydet
                </Button>
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
    );
  } else {
    return (
      <div className="personelInfoBox">
        <div className="labelRow">
          <div className="label">E-Posta</div>
          <span>{user.email}</span>
        </div>
        <div className="labelRow">
          <div className="label">Adı-Soyadı </div>
          <span>{user.name}</span>{" "}
        </div>
        <div className="labelRow">
          <div className="label">Telefon </div>
          <span>{user.phone}</span>{" "}
        </div>
        <div className="labelRow">
          <div className="label">Doğum Yılı </div>
          <span>{user.birth_year}</span>
        </div>
        <div className="buttonsspace">
          <Link to="/documents">
            <Button className="historyButton">
              <HistoryIcon style={{ marginRight: 5, fontSize: 18 }} />
              Belgelerim
            </Button>
          </Link>
          <Button
            className="editButton"
            variant="contained"
            onClick={() => {
              setEditMode(true);
            }}
          >
            <EditIcon style={{ fontSize: 18, marginRight: 3 }} /> Düzenle
          </Button>
        </div>
      </div>
    );
  }
};
const hallOfFameInputBox = (user, season) => {
  const { activeProduct } = useContext(AppContext);
  const [about, setAbout] = useState(user?.about || "");
  const [modalOpen, setModalOpen] = useState(false);
  const [imageSrc, setImageSrc] = useState(null);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [rotation, setRotation] = useState(0);
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [croppedImage, setCroppedImage] = useState(null);
  const [isPhotoUploading, setIsPhotoUploading] = useState(false);
  const [isHofInfoLoading, setIsHofInfoLoading] = useState(false);
  const [showUserHallOfFamesComponents, setShowUserHallOfFamesComponents] =
    useState({
      showCongratTitle: true,
      showHallOfFameBox: true,
    });

  const imageUploaderRef = useRef(null);

  useEffect(() => {
    getHallOfFameUser(activeProduct?.id, user.uid).then((user) => {
      if (user.about) {
        setShowUserHallOfFamesComponents({
          showCongratTitle: false,
          showHallOfFameBox: false,
        });
      }
    });
  }, [activeProduct?.id]);

  const onCropComplete = (croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  };
  const showCroppedImage = async () => {
    try {
      const croppedImage = await getCroppedImg(
        imageSrc,
        croppedAreaPixels,
        rotation
      );
      setCroppedImage(croppedImage);
      setModalOpen(false);
    } catch (e) {
      console.error(e);
    }
  };
  function handlefocusback() {
    setIsPhotoUploading(false);

    window.removeEventListener("focus", handlefocusback);
  }
  const onFileInputClicked = () => {
    setIsPhotoUploading(true);
    imageUploaderRef.current.value = null;
    setImageSrc(null);
    setCroppedImage(null);
    window.addEventListener("focus", handlefocusback);
  };
  function readFile(file) {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.addEventListener("load", () => resolve(reader.result), false);
      reader.readAsDataURL(file);
    });
  }
  const onFileChange = async (e) => {
    if (e.target.files && e.target.files.length > 0) {
      setModalOpen(true);
      const file = e.target.files[0];
      let imageDataUrl = await readFile(file);
      setImageSrc(imageDataUrl);
    }
    setIsPhotoUploading(false);
  };

  const handleModalClose = () => {
    imageUploaderRef.current.value = null;
    setModalOpen(false);
    setIsPhotoUploading(false);
  };

  const handleSubmitHallOfFameInfo = async () => {
    if (about.trim().length < 100) {
      alert("Lütfen özgeçmişinizi en az 100 karakter olarak doldurunuz.");
      return;
    }
    setIsHofInfoLoading(true);
    let photoURL = "";
    if (croppedImage) {
      const pureData = croppedImage.split(",")[1];
      var sizeInBytes = 4 * Math.ceil(pureData.length / 3) * 0.5624896334383812;
      var sizeInKb = sizeInBytes / 1000;
      if (sizeInKb > 20 * 1024) {
        alert("Fotoğraf boyutu 20MB'dan büyük olamaz.");
        return;
      }
      photoURL = await uploadImageToDb(croppedImage.split(",")[1], user.uid);
    }

    try {
      await addAboutToDb(about.trim(), season.id, user.uid, photoURL);
      setAbout("");
      setCroppedImage(null);
      setShowUserHallOfFamesComponents({
        showCongratTitle: false,
        showHallOfFameBox: false,
      });
      alert("Özgeçmişiniz başarıyla kaydedildi.");
    } catch (error) {
      alert("Özgeçmişiniz kaydedilirken bir hata oluştu.");
    }
    setIsHofInfoLoading(false);
  };

  return (
    <>
      <div className="hallOfFameBox">
        <p className="titleHallOfFame">
          {showUserHallOfFamesComponents.showCongratTitle
            ? "2024 Yılı Şeref Kürsüsü'nde yer almaya hak kazandınız. Tebrikler! Kürsüde fotoğrafınızın ve özgeçmişinizin yer alması için aşağıdaki alanları doldurunuz. Kaydet butonuna bastığınız anda bilgileriniz yayınlanacaktır"
            : "Şeref Kürsüsü için bilgileriniz yüklenmiştir."}{" "}
          31 Ocak 2025 tarihine kadar bilgilerinizi güncelleyebilirsiniz.
        </p>
        {!showUserHallOfFamesComponents.showCongratTitle && (
          <p
            style={{ color: "dodgerblue", cursor: "pointer" }}
            onClick={() =>
              setShowUserHallOfFamesComponents({
                showCongratTitle: false,
                showHallOfFameBox: true,
              })
            }
          >
            Bilgilerinizi yenilemek için tıklayınız
          </p>
        )}

        {showUserHallOfFamesComponents.showHallOfFameBox && (
          <>
            <div className="labelRow">
              <div className="label">Fotoğraf</div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <div
                  style={{
                    width: "100%",
                    height: 200,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    borderRadius: 1,
                    marginTop: 5,
                    marginBottom: 5,
                  }}
                >
                  {croppedImage ? (
                    <img
                      src={croppedImage}
                      style={{ height: 160, width: 160 }}
                      alt="profile"
                    />
                  ) : (
                    <div
                      style={{
                        backgroundColor: "#00000017",
                        height: 160,
                        width: 160,
                        borderRadius: 2,
                      }}
                    ></div>
                  )}
                  <div
                    style={{
                      textAlign: "center",
                      backgroundColor: "#87E0AE",
                      borderRadius: 3,
                      padding: 8,
                      color: "black",
                      fontWeight: "bold",
                      opacity: isPhotoUploading ? 0.5 : 1,
                    }}
                  >
                    <label style={{ cursor: "pointer" }} htmlFor="fileUploader">
                      Fotoğraf Yükle
                    </label>
                  </div>
                </div>
              </div>
              <input
                type="file"
                accept="image/png, image/jpg, image/jpeg"
                ref={imageUploaderRef}
                onChange={onFileChange}
                id="fileUploader"
                hidden
                onClick={onFileInputClicked}
              />
            </div>
            <div className="labelRowAbout">
              <div className="label">Özgeçmişiniz </div>

              <TextField
                id="about"
                label="Öçgeçmiş"
                name="about"
                value={about}
                onChange={(e) => {
                  if (e.target.value.length < 2000) setAbout(e.target.value);
                  else alert("Özgeçmişiniz 2000 karakterden uzun olamaz.");
                }}
                autoComplete={"disabled"}
                style={{ width: "90%" }}
                size="small"
                error={about.length < 3}
                multiline
                rows={8}
                sx={{ mb: 3, ml: 1 }}
              />
            </div>
            <Button
              className="uploadButton"
              variant="contained"
              onClick={handleSubmitHallOfFameInfo}
              disabled={isHofInfoLoading}
              style={{ opacity: isHofInfoLoading ? 0.5 : 1 }}
            >
              Yükle
            </Button>
          </>
        )}
      </div>
      <Modal
        open={modalOpen}
        onClose={handleModalClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Cropper
            image={imageSrc}
            crop={crop}
            rotation={rotation}
            zoom={zoom}
            aspect={4 / 4}
            onCropChange={setCrop}
            onRotationChange={setRotation}
            onCropComplete={onCropComplete}
            onZoomChange={setZoom}
            style={{
              containerStyle: {
                width: 400,
                height: 300,
                border: "1px black solid",
                marginLeft: window.innerWidth / 2 - 200,
                top: 30,
              },
            }}
          />
          <Typography variant="subtitle1" sx={{ color: "white" }}>
            Fotoğrafınızı kırpın ve "Fotoğrafı Kullan" butonuna tıklayın.
            Yakınlaştırmak ve uzaklaştırmak için fare tekerleğini kullanın
          </Typography>
          <Button
            sx={{ mt: 5, width: 300 }}
            variant="contained"
            onClick={showCroppedImage}
          >
            Fotoğrafı Kullan
          </Button>
        </div>
      </Modal>
    </>
  );
};
const GenerateActiveProductBox = () => {
  const { refreshUserData, setCentralState, user, activeProduct } =
    React.useContext(AppContext);
  const [isPurchseTriggered, setIsPurchaseTriggered] = useState(false);
  if (!user) return null;

  const activeExams = activeProduct?.exams || [];

  if (activeExams.length === 0) {
    return (
      <div className="activeProductBox">
        <h2>Şu an aktif sınav bulunmamaktadır.</h2>
      </div>
    );
  }

  const isAllCompulsaryFieldsFilled =
    user &&
    user.name &&
    user.birth_year &&
    user.gender &&
    user.city &&
    user.education &&
    user.occupation;

  let userProductData = user.products && user.products[activeProduct.id];

  const isPaymentSuccessfull = userProductData?.is_paid;

  let elemeResultId = "no_result";
  let elemeResultText = "";

  let yfResultId = "no_result";
  let yfResultText = "";

  let finalDerecesiId = "no_result";

  if (userProductData) {
    const { eleme_sonuc, yf_sonuc, final_derecesi } = userProductData;
    elemeResultId = eleme_sonuc || "no_result";
    yfResultId = yf_sonuc || "no_result";
    finalDerecesiId = final_derecesi || "no_result";
    elemeResultText =
      activeExams[0]?.result_texts &&
      activeExams[0].result_texts[elemeResultId];
    yfResultText =
      activeExams[0]?.result_texts && activeExams[1]?.result_texts[yfResultId];
  }

  const purchaseClicked = async () => {
    if (!user.name || !user.birth_year || !user.phone) {
      alert(
        "Ödeme adımına ilerlemeden önce lütfen ad-soyad, telefon ve doğum tarihi bilgilerinizi eksiksiz olarak doldurunuz."
      );
      return;
    }

    if (!activeProduct.payment_start_at) {
      alert("HATA: Ödeme başlangıç tarihi henüz belirlenmemiştir.");
      return;
    }
    if (activeProduct.payment_start_at > new Date()) {
      alert(
        "Ödeme aşaması henüz başlamamıştır. Takvimde belirtilen Eleme Sınavı başlangıç tarihinde ödeme yapabilirsiniz."
      );
      return;
    }
    if (!activeProduct.payment_end_at) {
      alert("HATA: Ödeme bitiş tarihi henüz belirlenmemiştir.");
      return;
    }
    if (activeProduct.payment_end_at < new Date()) {
      alert("HATA: Ödeme süresi sona ermiştir.");
      return;
    }

    setIsPurchaseTriggered(true);
    // Daha önce ödeme var mı kontrol edelim:
    let paymentToken =
      user.products && user.products[activeProduct.id]?.payment_token;
    if (paymentToken) {
      const pymnt = await getPayment(paymentToken);
      const isPaid = pymnt?.status === "successful";
      if (isPaid) {
        alert("Daha önce ödeme yapılmış. Sınavınız aktif hale getirilecektir.");
        const res = await markPaymentAsSuccessful(user.uid, activeProduct.id);
        if (res) {
          await refreshUserData();
        }
      } else {
        // alert("Token var ama ödeme yapılmamış.. Tokenı kullanarak ödeme sayfasına yönlendir.");
        const redirectUrl = HALICI_PAYMENT_HOST + "payments/" + paymentToken;
        window.location.href = redirectUrl;
      }
    } else {
      // alert("PAyment yok, backend'den url talep et.");
      const params = {
        receipt_id: 9999,
        user_id: 9999,
        name: user.name,
        email: user.email,
        uid: user.uid,
        requester_url: window.location.origin,
      };
      const paymentUrlResponse = await getPaymentUrl(params);
      // console.log("paymentUrlResponse", paymentUrlResponse);

      if (paymentUrlResponse.error_message) {
        alert(paymentUrlResponse.error_message);
      } else if (paymentUrlResponse.url) {
        //bunu kaydedelim ayrıca:
        const url = paymentUrlResponse.url;
        const arr = url.split("/");
        const tkn = arr[arr.length - 1];
        const newData = {
          products: { [activeProduct.id]: { payment_token: tkn } },
        };
        await saveUserData(user.uid, newData);
        window.location.href = url;
      } else
        alert("Ödeme ekranı açılırkan bir sorun oluştu. (Hata Kodu: BT91Z4)");
    }
    setIsPurchaseTriggered(false);
  };

  const lockScreenForPayment = (
    <div className="lock-screen">
      <div className="lock-screen-inner-box">
        <LockIcon style={{ fontSize: "xxx-large", marginBottom: 5 }} />
        {/* <span>
          OYUN-2023 için kayıt/ödeme süreci sona ermiştir. OYUN-2024'te görüşmek
          üzere...
        </span> */}
        <span>SINAVLARA ERİŞEBİLMEK İÇİN LÜTFEN ÖDEME YAPINIZ</span>
        <Button
          className="purchaseButton"
          variant="contained"
          color="secondary"
          disabled={isPurchseTriggered}
          onClick={purchaseClicked}
        >
          {isPurchseTriggered ? "Lütfen Bekleyiniz" : "Ödeme Sayfası"}
        </Button>
      </div>
    </div>
  );

  const lockScreenForProfileData = (
    <div className="lock-screen">
      <div className="lock-screen-inner-box">
        <LockIcon style={{ fontSize: "xxx-large", marginBottom: 5 }} />
        <span> LÜTFEN EKSİK PROFİL BİLGİLERİNİZİ GÜNCELLEYİNİZ.</span>
      </div>
    </div>
  );

  const elemeAdditionalComponents = activeProduct?.id === "oyun2024" && (
    <>
      {/* <p className="small-announcement">
        Tüm Oyun2024 duyuruları, hatırlatmalar, yaklaşan sınav tarihleri ve
        itiraz süreci gibi önemli açıklamalar, Whatsapp duyuru kanalımız
        aracılığıyla yapılacaktır. Aşağıdaki bağlantıyı kullanarak kanalımıza
        katılabilirsiniz. Telefon numaranız diğer üyelerle paylaşılmayacaktır.
        İstediğiniz zaman kanaldan çıkabilirsiniz.
      </p>
      <p>
        <a
          href="https://whatsapp.com/channel/0029VabTkl305MUjehocPd0S"
          target="_blank"
          rel="noreferrer"
        >
          Whatsap Duyuru Kanalı
        </a>
      </p>
      <p className="small-announcement">
        (Kanala üye olduktan sonra kanal ayarlarından bildirimleri açmayı
        unutmayınız.)
      </p>
*/}
      {/* <a
        target={"_blank"}
        style={{ marginTop: 23 }}
        rel="noreferrer"
        href={`https://docs.google.com/forms/d/e/1FAIpQLSfQdvkUWMFhsorRLyWyDY7c0KdjQsZ7-gMXZ0flwbkO0U31qA/viewform?usp=pp_url&entry.1157331533=${user.uid}&entry.427270648=${user.email}`}
      >
        Soru/Cevap İtiraz Formu
      </a> */}
      {/* {elemeResultId === 1 && <SuccessDocument2023 userName={user.name} />} */}
      {/* {elemeResultId === 2 && <AttendanceDocument2023 userName={user.name} />} */}
      {/* {(elemeResultId === 2 || (elemeResultId === 1 && yfResultId !== 1)) && // TODO: seneye eleme kesin sonuçları yayınlandığı anda bu satır yüzünden elemede başarılı olanlara sebf linki gösterilir. Bunu engellemek için OR kısımını silip sadece eleme_res_id==2 olanlara sebf verecek şekilde deploy yap.
        (user.birth_year || 0) > 2004 && ( //İlk 2 turda elenen genç-çocuk-miniklere sosyal etkinlik formlarını verelim. (
          <ElemeSebfDocument2023
            userName={user.name}
            fileNumber={user.products.oyun2023.file_number}
          />
        )} */}
      {elemeResultText && (
        <span
          style={{ marginTop: 15, color: "green", textDecoration: "underline" }}
        >
          {elemeResultText}
        </span>
      )}
    </>
  );

  const yfAdditionalComponets = activeProduct?.id === "oyun2024" && (
    <>
      {/* <a
        target={"_blank"}
        style={{ marginTop: 23 }}
        rel="noreferrer"
        href={
          "https://docs.google.com/forms/d/1IjXRjdokacZrQU8OWVbK9DHkcL4XRCr_EMc1tbAxi-k/viewform?usp=pp_url&entry.1157331533=" +
          user.uid +
          "&entry.427270648=" +
          user.email
        }
      >
        Soru/Cevap İtiraz Formu
      </a> */}
      {/* {yfResultId === 1 && <YFSuccessDocument2022 userName={user.name} />} */}
      {/* {finalDerecesiId === "no_result" && (user.birth_year || 0) > 2004 && (
        <ElemeSebfDocument2023
          userName={user.name}
          fileNumber={user.products.oyun2023?.file_number}
        />
      )} */}

      {yfResultText && (
        <span
          style={{ marginTop: 15, color: "green", textDecoration: "underline" }}
        >
          {yfResultText}
        </span>
      )}
    </>
  );

  const publicInformationUrlButton = async () => {
    // const publicInformationFileUrl = await getPublicUrlOfFile(
    //   "2023_public/1_final_sinavi/FİNAL SINAVI BİLGİLENDİRME NOTU.pdf"
    // );
    // fetchFileFromUrl(publicInformationFileUrl);
    // console.log("file_url: ", publicInformationFileUrl);
  };

  const onClickAnswerKeys = async () => {
    const publicInformationFileUrl = await getPublicUrlOfFile(
      "2023_public/1_final_sinavi/FİNAL SINAVI CEVAP ANAHTARLARI.pdf"
    );
    fetchFileFromUrl(publicInformationFileUrl);

    // console.log("file_url: ", publicInformationFileUrl);
  };

  const publicInformationButton = user && (
    <div>
      <a
        href="https://firebasestorage.googleapis.com/v0/b/oyun-5302a.appspot.com/o/2024_public%2F1_final_sinavi%2FF%C4%B0NAL%20SINAVI%20B%C4%B0LG%C4%B0LEND%C4%B0RME%20NOTU.pdf?alt=media&token=0e6d8776-6c83-4817-99b3-4b8d2d7fdbde"
        target="_blank"
        rel="noreferrer"
      >
        <Button
          style={{
            height: 60,
            backgroundColor: "#b0bec5",
            textTransform: "none",
            fontSize: "16px",
            color: "black",
            fontWeight: "bold",
            fontFamily: "sans-serif",
          }}
          variant="contained"
          onClick={publicInformationUrlButton}
        >
          Final Sınavı Bilgilendirme Notu
        </Button>
      </a>
    </div>
  );

  const answerKeys = user && (
    <div style={{ marginTop: 10 }}>
      <a
        href="https://firebasestorage.googleapis.com/v0/b/oyun-5302a.appspot.com/o/2024_public%2F1_final_sinavi%2FCevap%20anahtar%C4%B1.pdf?alt=media&token=9c5913b6-9cd7-4d30-8f2f-24008405b4d7"
        target="_blank"
        rel="noreferrer"
      >
        <Button
          style={{
            width: 180,
            height: 60,
          }}
          color="secondary"
          variant="contained"
          // onClick={onClickAnswerKeys}
        >
          FİNAL SINAVI CEVAP ANAHTARLARI
        </Button>
      </a>
    </div>
  );

  const dereceXlsx = user && (
    <div style={{ marginTop: 10 }}>
      <a
        href="https://docs.google.com/spreadsheets/d/1YTcuLvyWOI-0Iu2Nes1ToU6f7-sBYnzz/edit?usp=sharing&ouid=101457187378283704471&rtpof=true&sd=true"
        target="_blank"
        rel="noreferrer"
      >
        <Button
          style={{
            width: 280,
            height: 100,
          }}
          color="secondary"
          variant="contained"
          //onClick={onClickAnswerKeys}
        >
          YARIŞMACILARIN DOĞRU CEVAPLARI VE DERECELERİ
        </Button>
      </a>
    </div>
  );

  const dereceInformation = user && (
    <div style={{ marginTop: 10 }}>
      <Button
        style={{
          width: 280,
          height: 100,
        }}
        color="secondary"
        variant="contained"
        //onClick={onClickAnswerKeys}
      >
        Final sınavına ait dereceler ve belgeler ilerleyen günlerde
        yayınlanacaktır.
      </Button>
    </div>
  );
  const fnAdditionalComponets = activeProduct?.id === "oyun2024" && (
    <>
      {/* {publicInformationButton} */}
      {answerKeys}

      {/* {dereceXlsx} */}
      {/* {dereceInformation} */}
      {/* 
      <a
        target={"_blank"}
        style={{ marginTop: 23 }}
        rel="noreferrer"
        href={
          "https://docs.google.com/forms/d/e/1FAIpQLSfPxfCDeAufj17Xo3PYlEyM3f1xKHraeYu1qhCo0d5nl94GMQ/viewform?usp=pp_url&entry.427270648=" +
          user.email
        }
      >
        İtiraz Formu
      </a> */}

      {/* {yfResultId === 1 && (user.birth_year || 0) > 2003 && (
        <FinalSebfDocument2022
          userName={user.name}
          fileNumber={user.products.oyun2022.file_number}
        />
      )}
      {yfResultId === 1 && <YFSuccessDocument2022 userName={user.name} />}

      {yfResultText && (
        <span
          style={{ marginTop: 15, color: "red", textDecoration: "underline" }}
        >
          {yfResultText}
        </span>
      )}
      {yfResultId === 1 && <YFSuccessDocument2023 userName={user.name} />}
      {finalDerecesiId > 0 && (user.birth_year || 0) > 2004 && (
        <FinalSebfDocument2023
          userName={user.name}
          fileNumber={user.products.oyun2023.file_number}
        />
      )} */}
    </>
  );

  return (
    <div className="activeProductBox">
      {/* {yfResultId === 1 && publicInformationButton} */}

      {!isAllCompulsaryFieldsFilled && lockScreenForProfileData}
      {isAllCompulsaryFieldsFilled &&
        !isPaymentSuccessfull &&
        lockScreenForPayment}
      <h2>{activeProduct.header}</h2>
      {activeExams.map((e, idx) => {
        if (idx > 0 && elemeResultId !== 1) return null;
        else if (idx === 2 && yfResultId !== 1) return null;
        let examId = "";
        let examQuestionsId = "";
        let exam_access_ids = e.exam_access_ids || [];
        let availableAtDateHasCome =
          e.available_at && e.available_at.toDate() < new Date();

        let categoryName = "";
        exam_access_ids.forEach((exam_category) => {
          const {
            birth_year_start,
            birth_year_end,
            exam_id,
            exam_questions_id,
            category,
          } = exam_category;
          if (
            user.birth_year >= birth_year_start &&
            user.birth_year <= birth_year_end
          ) {
            examId = exam_id;
            examQuestionsId = exam_questions_id;
            categoryName = category;
          }
        });

        let linkReady = examId && examQuestionsId;

        const userName = user?.name || "";

        const nameForUrl = userName.trim().replaceAll(" ", "+");
        let osis_link =
          "https://sinav.tzv.org.tr/linkedExam/" +
          examId +
          "&" +
          examQuestionsId +
          "&" +
          user.uid +
          "&" +
          nameForUrl;

        const examTitle = (
          <h3 style={{ margin: 0 }}>
            {e.title + (categoryName ? " (" + categoryName + ")" : "")}
          </h3>
        );

        const examExpo = <p style={{ textAlign: "justify" }}>{e.expo}</p>;

        const startExamButton = linkReady &&
          availableAtDateHasCome &&
          isPaymentSuccessfull && (
            <a
              style={{ textDecoration: "none" }}
              target="_blank"
              href={osis_link}
              rel="noreferrer"
            >
              <Button color="secondary" variant="contained">
                Sınav Ekranı
              </Button>
            </a>
          );

        const properExamLinkNotFoundExplanation = availableAtDateHasCome &&
          isPaymentSuccessfull && <span>Sınav Kategoriniz Belirlenemedi</span>;

        return (
          <div key={idx + "exam_box"} className="examBox">
            {examTitle}
            {examExpo}
            {startExamButton}
            {idx === 0 && elemeAdditionalComponents}
            {idx === 1 && yfAdditionalComponets}
            {idx === 2 && fnAdditionalComponets}
            {!linkReady && properExamLinkNotFoundExplanation}
          </div>
        );
      })}

      <div>{activeProduct.bottom_note}</div>
    </div>
  );
};
