import React from "react";
import AppContext from "../../AppContext";
import { SuccessDocument2024 } from "../../components/organisms/pdfs/2024/successDocument";
import { AttendanceDocument2024 } from "../../components/organisms/pdfs/2024/attendanceDocument";
import { FinalSebfDocument2024 } from "../../components/organisms/pdfs/2024/05_finalSebfDocument";
import { YfSebfDocument2024 } from "../../components/organisms/pdfs/2024/06_yfSebfDocument";
import { ElemeSebfDocument2024 } from "../../components/organisms/pdfs/2024/04_elemeSebfDocument";
import { YFSuccessDocument2024 } from "../../components/organisms/pdfs/2024/yf_successDocument";

const Year2024 = () => {
  const { user } = React.useContext(AppContext);
  const userProductData = user.products;
  const product2024 = userProductData?.oyun2024;

  const elemeKatilim = product2024?.eleme_sonuc === 2 && (
    <AttendanceDocument2024 userName={user.name} />
  );
  const elemeBasari = product2024?.eleme_sonuc === 1 && (
    <SuccessDocument2024 userName={user.name} />
  );
  const elemeSebf =
    product2024?.eleme_sonuc === 2 && (user?.birth_year || 0) > 2005 ? (
      <ElemeSebfDocument2024
        userName={user.name}
        fileNumber={product2024.file_number}
      />
    ) : null;

  const yfBasari = product2024?.yf_sonuc === 1 && (
    <YFSuccessDocument2024 userName={user.name} />
  );

  const yfSebf = product2024?.yf_sonuc === 2 &&
    (user?.birth_year || 0) > 2005 && (
      <YfSebfDocument2024
        userName={user.name}
        fileNumber={user.products?.oyun2024?.file_number}
      />
    );

  const finalSebf = product2024?.final_derecesi &&
    (user?.birth_year || 0) > 2005 && (
      <FinalSebfDocument2024
        userName={user.name}
        fileNumber={user.products?.oyun2024?.file_number}
      />
    );

  // const noDocuments =
  //   !elemeKatilim && !elemeBasari && !yfBasari && !elemeSebf && !finalSebf ? (
  //     <p>Eleme sınavı tamamlandıktan sonra belgeleriniz erişime açılacaktır.</p>
  //   ) : null;

  const noDocuments =
    !elemeKatilim && !elemeBasari && !yfBasari && !elemeSebf && !finalSebf ? (
      <p>Bu kategoride belgeniz bulunmamaktadır.</p>
    ) : null;

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <div style={{ display: "flex", gap: "16px" }}>
        {elemeKatilim}
        {elemeBasari}
        {yfBasari}
        {elemeSebf}
        {finalSebf}
        {yfSebf}
      </div>
      {noDocuments}
    </div>
  );
};

export default Year2024;
