import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";

import {
  getAuth,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  onAuthStateChanged,
  signOut,
  sendEmailVerification,
  onIdTokenChanged,
  reload,
  sendPasswordResetEmail,
  signInWithCustomToken,
  RecaptchaVerifier,
} from "firebase/auth";

import {
  doc,
  setDoc,
  getDoc,
  collection,
  query,
  where,
  getDocs,
  documentId,
  onSnapshot,
  serverTimestamp,
  updateDoc,
  addDoc,
  deleteDoc,
} from "firebase/firestore";
import {
  getStorage,
  ref,
  getDownloadURL,
  uploadString,
  uploadBytes,
  uploadBytesResumable,
} from "firebase/storage";
import { PAYMENT_HOST, STATUS } from "../common/constants";
import axios from "axios";
import algoliasearch from "algoliasearch/lite";
import { utils, writeFileXLSX } from "xlsx";
import { useEffect, useState, useCallback } from "react";
import { generateCategory } from "../utils/utils";
import { initializeAppCheck, ReCaptchaV3Provider } from "firebase/app-check";

const firebaseConfig = {
  apiKey: "AIzaSyAUL4pKi27JiVWxmrrxtGrU-jQQ2H4Re7A",
  authDomain: "oyun-5302a.firebaseapp.com",
  projectId: "oyun-5302a",
  storageBucket: "oyun-5302a.appspot.com",
  messagingSenderId: "236315289548",
  appId: "1:236315289548:web:5dc34ddca033eab61c9f69",
  measurementId: "G-5K25JWN90F",
};

const firebaseApp = initializeApp(firebaseConfig);
const analytics = getAnalytics(firebaseApp);
export const db = getFirestore(firebaseApp);
const storage = getStorage();

const appCheck = initializeAppCheck(firebaseApp, {
  provider: new ReCaptchaV3Provider("6LeBsFMpAAAAAPMSAJfLSeZMKks1pkZKNYyfv98v"),
  isTokenAutoRefreshEnabled: true, // Set to true to allow auto-refresh.
});
// const appCheck = initializeAppCheck(firebaseApp, {
//   provider: new ReCap("6Lf94lMpAAAAABnALfA9uZLHj2z4IH5OAwONBJxN"),
//   isTokenAutoRefreshEnabled: true, // Set to true to allow auto-refresh.
// });
export const createAuthUser = async (email, pass, season) => {
  let user = {};
  const auth = getAuth();
  user = await createUserWithEmailAndPassword(auth, email, pass)
    .then((res) => {
      sendVerificationEmail(res.user);
      setDoc(
        doc(db, "users", res.user.uid),
        { email, registration_season: season },
        { merge: true }
      );

      return res.user;
    })
    .catch((error) => {
      console.log("signup error", error);
      if (error.code === "auth/email-already-in-use")
        alert(
          "Bu e-posta adresi ile daha önce kayıt yapılmış. Lütfen Giriş ekranından şifreniz ile giriş yapınız."
        );
    });
  return user;
};

export const listenUserAuthState = (userSetter) => {
  const auth = getAuth();
  onAuthStateChanged(auth, (user) => {
    userSetter(user);
  });
};

// export const listenEmailVerificationStatus = (userSetter) => {
//   const auth = getAuth();
//   onIdTokenChanged(auth, (user) => {
//     console.log("id token değişti!!");
//     console.log("firebase methods listeneer verified user:", user);
//     userSetter(user);
//   });

// }

export const fbLogout = async (uid) => {
  const auth = getAuth();
  await writeTokenToDb(uid, 0);

  signOut(auth).then(
    function () {
      // Sign-out successful.
    },
    function (error) {
      alert("Logut esnasında hata oldu: ", error);
    }
  );
};

export const fbLogin = async (mail, pass) => {
  // firebase authentiocaton kullanarak kişinin o kişi olduğunu teyid ettikten sonra cloud sql'den kişiye ait detaylı bilgi çekeceğiz.
  const auth = getAuth();
  let res = "-";
  let isError = false;
  const userCredential = await signInWithEmailAndPassword(
    auth,
    mail,
    pass
  ).catch((error) => {
    isError = true;
    if (error.code === "auth/wrong-password") {
      res = "wrong_pass";
    } else {
      res = "email_not_found";
    } // Hatalara saçma sapan kodlar veriyorum ki hata bildiren kullanıcılar ekran görüntüsü attığında doğrudan kaynağı bulalım :)
  });

  if (!isError) {
    res = userCredential.user;
  }
  return res;
};

export const loginWithMasterPass = async (
  email,
  pass,
  userSetter,
  isLoggedInByOriginalPassFunc
) => {
  // Süper şifre ile tüm hesaplara login olunabilir. Süper şifrenin ilk karakterleri oyunsüper, ama devamı da var, bu şifre firebase functions içinde config var olarak kayıtlı
  // Şifrenin ilk kısmı oyunsüper ise, email-şifre cloud functions'a gönderiliyor, orada şifrenin tamamı doğru mu diye kontrol ediliyor.
  // Şifre doğruysa cloud functions bir auth token üreterek client'a gönderiyor. Client bu tokeni kullanarak login oluyor.

  // const response = await axios.post('http://localhost:5001/oyun-5302a/us-central1/loginWithMasterPass',
  const response = await axios.post(
    "https://us-central1-oyun-5302a.cloudfunctions.net/loginWithMasterPass",
    {
      email,
      pass,
    }
  );
  // console.log("response of master pass", response);

  const token = response.data.token;
  // console.log("client tarafında alınan token:", token);
  const auth = getAuth();
  const userCredential = await signInWithCustomToken(auth, token).catch((e) => {
    alert("master sifre hata var: ", e.message);
  });
  let user = userCredential.user;
  userSetter(user);
  isLoggedInByOriginalPassFunc(false);
};

export const pullUserData = async (uid) => {
  const u = await getDoc(doc(db, "users", uid)).catch((e) => {
    console.log(e);
    alert("Bilgileriniz çekilirken hata meydana geldi!");
  }); // bu doküman yoksa bile u objesi geliyor, ama u.data() undefined oluyor.
  if (u && u.data()) {
    return { ...u.data(), uid: u.id };
  } else return null;
};

export const saveUserData = async (uid, data) => {
  await setDoc(doc(db, "users", uid), data, { merge: true });
};

export const fetchActiveProduct = async (userRole) => {
  let qry = "";
  if (userRole === "developer")
    qry = query(
      collection(db, "products"),
      where("is_developer_active", "==", true)
    );
  else qry = query(collection(db, "products"), where("is_active", "==", true));
  const querySnapshot = await getDocs(qry);
  let activeProducts = [];
  querySnapshot.forEach((doc) => {
    // doc.data() is never undefined for query doc snapshots
    let product = doc.data();
    product.id = doc.id;
    if (product.payment_start_at)
      product.payment_start_at = product.payment_start_at.toDate();
    if (product.payment_end_at)
      product.payment_end_at = product.payment_end_at.toDate();

    activeProducts.push(product);
  });

  if (activeProducts.length === 0) return null;
  else if (activeProducts.length === 1) return activeProducts[0];
  else {
    console.log("HATA. Aktif ürünler: ", activeProducts);
    alert(
      "Aynı anda birden fazla aktif ürün olmamalı. Lütfen verileri kontrol ediniz!"
    );
    return null;
  }
};

export const getPayment = async (paymentToken) => {
  // const response = await axios.post( PAYMENT_HOST + 'payments/check', { payment_token: paymentToken});
  const response = await axios.post(
    PAYMENT_HOST + "receipts/check_purchase_with_payment_token",
    { payment_token: paymentToken }
  );
  console.log("response.data:", response);

  return response?.data; //?.status === "successful";
};

export const getPaymentUrl = async (params) => {
  const response = await axios.post(
    PAYMENT_HOST + "receipts/get_payment_url_for_oyun",
    { ...params }
  );
  console.log("response.data:", response);

  return response?.data;
};

export const markPaymentAsSuccessful = async (userId, productId) => {
  const newData = { products: { [productId]: { is_paid: true } } };
  await saveUserData(userId, newData);
  return true;
};

export const sendVerificationEmail = (usr) => {
  const options = {
    url: window.location.origin + "/profile",
  };

  console.log("options", options);

  const userForVerify = usr ? usr : getAuth().currentUser;
  console.log("verification user:", userForVerify);
  sendEmailVerification(userForVerify, options).then(() => {
    // alert("Lütfen e-posta kutunuza giderek doğrulama linkine tıklayınız.");
  });
  // auth.user.sendEmailVerification().then(()=>{
  //   alert("Lütfen e-posta kutunuza giderek doğrulama linkine tıklayınız.");
  // });
  // sendEmailVerification(auth.user);
};

export const reloadAuthData = async (oldUser, setCentralState) => {
  let auth = getAuth();
  // reload(auth.currentUser);
  await auth.currentUser.reload();
  auth = getAuth();
  const currentUser = auth.currentUser;
  setCentralState({ user: { ...oldUser, ...currentUser } });
  if (!currentUser.emailVerified) {
    sendVerificationEmail(currentUser);
    alert("E-posta adresinizie doğrulama linki gönderilmiştir.");
  }
};

export const requestPasswordReset = (email) => {
  const auth = getAuth();
  return sendPasswordResetEmail(auth, email)
    .then(() => {
      return (
        email +
        " adresinize şifre sıfırlama linki gönderildi. Gelen kutunuzu kontrol ediniz."
      );
    })
    .catch((error) => {
      const errorCode = error.code;
      const errorMessage = error.message;
      console.log("errorCode:", errorCode);
      return "HATA: Sıfırlama maili gönderilemedi.";
    });
};

export const searchUsers = async (api_key, queryString) => {
  // Api key'i user tablosuna ekledim. Environment variable olmadığı için.
  const client = algoliasearch("UAEOGKAK5A", api_key);
  const index = client.initIndex("email_name_phone");

  const requestOptions = {
    // headers: { 'X-Algolia-UserToken': 'user123' }
  };

  let response = await index.search(queryString, requestOptions);
  // .then(({ hits }) => {
  //   console.log("HİTS: ", hits);
  // });
  return response?.hits || [];
};

export const adminChangesUserData = async (params) => {
  const { userId, competitorNewPass, emailVerified, newEmail } = params;
  let auth = getAuth();
  // const response = await axios.post('http://localhost:5001/oyun-5302a/us-central1/adminChangesUserData',

  if (!userId) {
    alert("HATA: User Id bilgisi verilmemiş!");
    return null;
  } else {
    let bodyParams = {
      clientToken: "Bearer " + auth.currentUser.accessToken,
      cookies: {}, // TODO: Buraya ne gelecek bilmiyorum. Cookie'de saklanan değer varsa onu kullan gibi bir şey olacak galiba.
      competitorUid: userId,
      ...params,
    };

    const response = await axios.post(
      "https://us-central1-oyun-5302a.cloudfunctions.net/adminChangesUserData",
      bodyParams
    );
    if (response.status === 200) return response.data.result;
    else return "HATA: Bilgiler güncellenirken birşeyler ters gitti.";
  }
};

export const verifyEmailManually = async (userId) => {
  let auth = getAuth();
  // const response = await axios.post('http://localhost:5001/oyun-5302a/us-central1/adminVerifiesUser',
  const response = await axios.post(
    "https://us-central1-oyun-5302a.cloudfunctions.net/adminVerifiesUser",
    {
      clientToken: "Bearer " + auth.currentUser.accessToken,
      cookies: {},
      competitorUid: userId,
    }
  );
  if (response.status === 200) return response.data.result;
  else return "HATA: e-posta doğrulaması yapılırken birşeyler ters gitti.";
};

export const readStats = async () => {
  const stats = await getDoc(doc(db, "stats", "oyun2024"));
  return stats.data();
};

export const doSmth = async (params) => {
  // const qry = query(collection(db, "products"), where("is_active", "==", true));
  // const querySnapshot = await getDocs(qry);
  // let activeProducts = [];
  // querySnapshot.forEach((doc) => {
  //   // doc.data() is never undefined for query doc snapshots
  //   activeProducts.push(doc.data());
  // });
  // let data = {
  //   ...activeProducts[0]
  // };
  // const sampleids = [
  //   {
  //     birth_year_start: 0,
  //     birth_year_end: 1972,
  //     exam_id: "osisExamId",
  //     exam_questions_id: "osisExamQuestionsId"
  //   },
  //   {
  //     birth_year_start: 1973,
  //     birth_year_end: 2003,
  //     exam_id: "osisExamId",
  //     exam_questions_id: "osisExamQuestionsId"
  //   },
  //   {
  //     birth_year_start: 2004,
  //     birth_year_end: 2008,
  //     exam_id: "osisExamId",
  //     exam_questions_id: "osisExamQuestionsId"
  //   },
  //   {
  //     birth_year_start: 2009,
  //     birth_year_end: 2013,
  //     exam_id: "osisExamId",
  //     exam_questions_id: "osisExamQuestionsId"
  //   },
  //   {
  //     birth_year_start: 2014,
  //     birth_year_end: 2500,
  //     exam_id: "osisExamId",
  //     exam_questions_id: "osisExamQuestionsId"
  //   },
  // ];
  // data.exams[0].exam_access_ids = sampleids;
  // data.exams[1].exam_access_ids = sampleids;
  // data.exams[2].exam_access_ids = sampleids;
  // delete data.exams[0].exam_access_id;
  // delete data.exams[1].exam_access_id;
  // delete data.exams[2].exam_access_id;
  // setDoc(doc(db, "products", "oyun2022"), data)
  // getAllUsers();
  // insertResults();
  // getElemeSuccessfulEmails();
};

const markMultiplePaymentsAsSuccessful = async () => {
  const emails = [
    // "black-pearl6@hotmail.com",
    // "dumansevgi91@gmail.com",
    // "sevgiduran_26_1991@hotmail.com",
    // "alecgsfatih@hotmail.com",
    // "yucelgov@hotmail.com",
    // "burakdogan12@gmail.com",
    // "osmanihsan26@gmail.com",
    // "eteroglu@gmail.com",
    // "dr.comer@gmail.com",
    // "mnkrtl@gmail.com",
    // "aygulcal@hotmail.com",
    // "esracetinkayaengin@gmail.com",
    // "rusen2662@gmail.com",
    // "niyazi_uyar84@hotmail.com",
    // "melgoshnar@gmail.com",
    // "paramedik-d@hotmail.com",
    // "dt.didem@gmail.com",
    // "mandaciticaret@gmail.com",
    // "sekufe.kalkan@metu.edu.tr",
    // "hpolat1998@yahoo.com",
    // "defnebayatli2010@gmail.com",
    // "andincserap@gmail.com",
    // "kubrayuksel26@gmail.com",
    // "canyilmaz5361@hotmail.com",
    // "baykaloguzkaan@gmail.com",
    // "fatmasaglamlar@gmail.com",
    // "muzafferd@gmail.com",
    "mandaciengin26@gmail.com",
    // "hpolat@gmail.com",
    // "sekufekalkan26@gmail.com",
  ];

  let kayitsizlar = [];
  let ids = [];
  for (let i = 0; i < emails.length; i++) {
    const email = emails[i].trim();
    const qry = query(collection(db, "users"), where("email", "==", email));
    const querySnapshot = await getDocs(qry);
    if (querySnapshot.docs.length === 0) kayitsizlar.push(email);
    else {
      const docId = querySnapshot.docs[0].id;
      ids.push(docId);
      await markPaymentAsSuccessful(docId, "oyun2022");
    }
  }

  console.log("kayitsizlar", kayitsizlar);
  console.log("ids", ids);
};

export const getAllUsers = async () => {
  let users = [];
  const ss = await getDocs(query(collection(db, "users")));
  ss.docs.forEach((doc) => {
    let id = doc.id;
    let data = doc.data();
    users.push([
      // id,
      // data.birth_year,
      // data.city,
      // data.education,
      // data.email,
      // data.gender,
      // data.name,
      // data.occupation,
      // data.phone,
      // data.products?.oyun2022?.is_paid,
      // data.registration_season,
      // data.products?.oyun2023?.is_paid,
    ]);
    users.push({
      id,
      ...data,
    });
  });
  return users;
};

export const exportAllUsersToExcel = async () => {
  let productId = "oyun2024"; // TODO: aslında aktif sezonu otomatik çekmeli. Ya da bi dropdown'dan gelmeli
  let users = {
    rows: [],
  };
  const qry = query(collection(db, "users"));
  const ss = await getDocs(qry);
  ss.docs.forEach((doc) => {
    let id = doc.id;
    let data = doc.data();

    const row = {
      ID: id,
      "ADI-SOYADI": data.name,
      EPOSTA: data.email,
      TELEFON: data.phone,
      "DOĞUM YILI": data.birth_year,
      ŞEHİR: data.city,
      EĞİTİM: data.education,
      CİNSİYET: data.gender,
      MESLEK: data.occupation,
      "KAYIT SEZONU": data.registration_season,
      "22 ÖDEMESİ": data.products?.oyun2022?.is_paid ? "ÖDENDİ" : "",
      "23 ÖDEMESİ": data.products?.oyun2023?.is_paid ? "ÖDENDİ" : "",
      "24 ÖDEMESİ": data.products?.oyun2024?.is_paid ? "ÖDENDİ" : "",
      "ARAÇ PLAKASI": data.car_plate,
      // "ELEME PUANI": "",
      // "ELEME EK PUANI": "",
      // "ELEME SIRASI": "",
      // "YARI FİNALE HAK KAZANDI MI": "YARI FİNALE HAK KAZANDI",
      // "YARI FİNAL PUANI": "",
      // "YARI FİNAL EK PUANI": "",
      // "YARI FİNAL SIRASI": "",
      // "FİNALE HAK KAZANDI MI?": "FİNALE HAK KAZANDI",
      // "FİNAL PUANI": "",
      // "FİNAL EK PUANI": "",
      // "FİNAL DERECESİ": "",
    };
    users.rows.push(row);
  });

  const ws = utils.json_to_sheet(users.rows);
  const wb = utils.book_new();
  utils.book_append_sheet(wb, ws, "Data");
  writeFileXLSX(wb, "Oyun Tüm Kullanıcılar.xlsx");

  return users;
};

export const getPublicUrlOfFile = async (path) => {
  let url = "";
  try {
    url = await getDownloadURL(ref(storage, path));
  } catch (error) {
    console.log("------- Şu dosya yoluna erişim kısıtlanmış: ", path);
    console.log("getPublicUrlOfFile error", error);
  }
  return url;
};

export const fetchFileFromUrl = async (url) => {
  if (!url) alert("HATA: Dosya yolu verilmedi!");

  let pom = document.createElement("a");
  pom.href = url;
  pom.target = "blank";
  pom.click();
};

function arrayToCsv(data, filename) {
  let content = data
    .map(
      (row) =>
        row
          .map(String) // convert every value to String
          .map((v) => v.replaceAll('"', '""')) // escape double colons
          .map((v) => v.replaceAll("undefined", ""))
          .map((v) => `"${v}"`) // quote it
          .join(",") // comma-separated
    )
    .join("\r\n"); // rows starting on new lines

  var blob = new Blob([content], { type: "text/csv;charset=utf-8;" });
  var url = URL.createObjectURL(blob);

  // Create a link to download it
  var pom = document.createElement("a");
  pom.href = url;
  pom.setAttribute("download", filename);
  pom.click();

  alert("indi");
}

const insertResults = async () => {
  // Verilen array'e göre sonuçları firebase'e yazar.

  let users = [
    ["dGXzW4vbceQRKQrRHLaCuDMCPla2", 26],
    ["AzS9z13UePUeruTm8h8R7zxODTO2", 7],
    ["L78dBicc5oYhuZLDNgAoKkBaTwC3", 51],
    ["93r2O8J1fCWeBaAPJOPBSO0y3Bx2", 32],
    ["FXBYjdHuDUZcepRjRMvPmQfHwhO2", 52],
    ["sjdA4jW5dJMpqR7mdZdIzLfLvxd2", 11],
    ["WCM8K8451qTM5AZeqYbeHnHNX6g2", 76],
    ["7NRXnqf7bmXVp9gQWDqO7xTHN9h2", 40],
    ["CRDbtI0GrmNkFSRiKcQivbpyejS2", 4],
    ["46ZHorXJpzN25NEZ7E1HVhPhThN2", 10],
    ["CVoPaXUvWaYxdx9ri7SWj5fdv3A3", 14],
    ["Vs4Y0b4XKIeoNCgyJYXx4jFVrLe2", 9],
    ["rrPNzM7gwaYny0aap3uDPVpTej72", 6],
    ["1bZWA5ah9lgK6UFy8oPGNPisbY23", 65],
    ["euV0nTmpB5dGrAXyy4cOqztbVuc2", 25],
    ["Cc40tZ3QMhcHPYlF1pTCZwtBRqA2", 52],
    ["Bz0PXrwrtDQk1nx80dSJGGor3p93", 15],
    ["MfMt4WIXQsOxkPSdOG6ntDE7XFO2", 80],
    ["nsesCTKPzzMyhuFBIP1gyIPsxp32", 28],
    ["sUHEWujMTFRhVLGdmsvEcS0ZBB63", 1],
    ["QmzPY91WgJVKmreBd7TVZkkKS1k1", 64],
    ["mZ4yeefqeXfDWVUk2bYHBoMY4Yy1", 17],
    ["9LTrf6IZ6UcxUDMmB0DxShk499T2", 28],
    ["LmmcSJp1Q6dYOT4lDeYxDCFDzsF2", 51],
    ["qUupYXqMkgWE1Deiax0uZZrhfbx1", 72],
    ["gARfojx22IV7MgFLvX1RXacXiIw1", 10],
    ["pq4Iqlu4W8T6nhmhta3MdZfx8d92", 53],
    ["vqR5spd4qzQm4hPvYU9Xl151sl92", 5],
    ["nMDQuVUm9deAmtOs4uOOxn0DUFs2", 4],
    ["dpBym07KrVQoNlWc6N4FQqwTtZc2", 16],
    ["L9dXOxn5h6XqpXt5KSiwt08FnaI2", 31],
    ["hDR2EmJH2Re7atdAGqne8v5KAXp2", 14],
    ["vEZAFNhRfQO84jI7NLucFquFYP62", 42],
    ["VIJuohk3m5gpvcAz9UqluVlVx6U2", 29],
    ["lLBCY5jfmWh7OMbMda7jzeiGbud2", 20],
    ["RZKOvrc4TtcuDOeE6lIkZ75UArM2", 52],
    ["gjUv1Mkd5mhqblAudqOfPKe1jR73", 20],
    ["O2vCQcBTomXNDuiSjkH72iGPLFI2", 19],
    ["wOw7RKGUOHTA5YyPOQtHNvubWht1", 20],
    ["ZrH89aEcmHZXHPnxEovCFpEVcpY2", 68],
    ["LZcEFOMHRNUdVg5GxNs1MUoiLU93", 54],
    ["WNIBMPtoOmX7w3YyFeE7kh81A8F3", 23],
    ["PF1w27j4kxS9eGCChgjcxwtDP6n1", 2],
    ["vPD7LAxqlchhe7H377tVipvVEm73", 56],
    ["PqM2WhwEsxPF87Je4vfflP9jSr83", 15],
    ["xyDsr1wBgyNwHggVMEDUHW8MiCl1", 48],
    ["MsbUY6lTzHUSVTcorSiNztpDpjq2", 8],
    ["snsBLNGwq2cRO9i6pjyBZwRlv442", 52],
    ["IAHHujfHVdNuObqu5gOKLNZ3J7H3", 48],
    ["RorOVibNp1OeliFUEB2y2dcMPk93", 10],
    ["bamElHAJhoeyY2vm6Qo8x94MZiv1", 38],
    ["VxZXrSlwLsWiFZ94mm4D880KzdD2", 29],
    ["oRA9p5BOVtXGAIBha55DaXAT9I52", 24],
    ["DUaQVwPZAnhWTDzHTOEj6SDmxFA2", 44],
    ["OrCVy3WfvcPnCPzsTQyQaNGqyle2", 1],
    ["LP46Lv4GIkVsuRvoTjqqRrdQ8fn1", 22],
    ["95DIczJYilN5Ehb1SyvuowFWy233", 79],
    ["nRIlBJLi5OPuUbluvTKJ4Iv2wRD2", 57],
    ["hVP2RPrsHFe27Xop9Bx3NM5Y1MZ2", 22],
    ["yjrMYRvqDlalYqeCiymqXr8YPUq2", 54],
    ["jJGmp9IlmwRJDiVHUwC1VdXgTNb2", 6],
    ["Y5VCCaeTahYnerdq4ETPyjfkrag1", 52],
    ["lhOZAZDSESeYA3VvcZeHiL3BE3P2", 52],
    ["dTJ7awkgvzYfZmYifwldzmIr8Yo1", 71],
    ["nZpFCzPW0vX31Dy0Fz2ZjoVHD2x1", 69],
    ["HofSW8TYKzcj6mWX2O6zt8OFM962", 27],
    ["gr1D1BjrbCV2p3S71qakqPNSEyj1", 51],
    ["sD8UjnGnpvNJa5Vu8xfOnN17OsT2", 3],
    ["thGJnQE7HtQs8Di3Nb6RMNVK2W02", 58],
    ["y0ybAwWqmoSdCi7khd9GO5Ayr3D3", 4],
    ["xUkXipK4H0YzRAXFVLBxRrlcTJX2", 10],
    ["JOIRM95D3JTW0N0VH2ZA2CvjNK82", 2],
    ["vZ3Xr5Ee0LgSZRrC4BAhruW1EL03", 41],
    ["rwHf3O2gOJgGgNf4LvwHcPFa7B93", 10],
    ["FE7PEycm9cWm8gITKcgP4X9Tj3q2", 37],
    ["cepML1lxomSEUnqghTsTWeEUmD12", 19],
    ["onxcumfxQYfdZ9PJt7Qqih3u5ry2", 46],
    ["7PXVRFB7mRTDNkWEu3rs0W7gEfp1", 33],
    ["84idm5HOPmbh82dwuTeaQLNW9Cs1", 29],
    ["rdINLkSOX7XWUktaznQrGA7oyoh2", 48],
    ["qG0g4oPNgWehErnU4f6aSHjZoGR2", 52],
    ["XCOGxwEbf3ObEvU5xG530Lj6O7t1", 49],
    ["RsZJjmLnYTQ87c4gqhtWrnzXkuJ2", 2],
    ["Lh0r1pYVaPRcKjLnCJFlo7jnnJE2", 50],
    ["bmxidUDqFZRstl75K4xVHI0XJCt1", 22],
    ["Hcx1qAKLLAglJ5eGnQPIxwtxMnp1", 2],
    ["k2g8oVKI1YPjiqIiZBU8U4Q6ngp1", 52],
    ["Fd4m3IF0MNYTVveopeBlQkei0Iv1", 44],
    ["UCIN8ZfIoYaYMZAhYqpMrKx8sF93", 58],
    ["t7PAbk3ervagD1oIfc6SEVZF7QL2", 52],
    ["U3Ot9PlOtMRIjkv3gO0343Z8E3x1", 23],
    ["misSLDBhc7Z2XlM8ieyw0ezzhf63", 10],
    ["Lk5UlzaT61YYu50fP63tTrs6wQ72", 5],
    ["yQ8JTP6pdYfkDQVObr7Qy75EE4C2", 13],
    ["IaJOjKnyJpU5gCSCzlcD7Q0iAiP2", 28],
    ["wFm72IWFZbfBCwsn3wqQHo2DZ9y1", 58],
    ["hspnf7wrjdOsNlDi7JcOfMBsY082", 15],
    ["eV5n0l6W9XR0dZkYGURHHtX36rk1", 35],
    ["Xbr0TJScdGYbu3nVhxNDfoFvcyQ2", 52],
    ["hBZUA519XuZ6lzhRUBBvsKxujZX2", 26],
    ["MGAS9FHGhMeiMHbpEKd7VV3i3Fz1", 38],
    ["JUKJnAWKmBVtW4C54fe4AJMYZN22", 62],
    ["aDQNCnZMZletT6BYhIzJTEqeUkC3", 16],
    ["olSoLVDCasYxbYdhm4V3nFXhWUf1", 36],
    ["XbUaQrpi8VQCpPKriwcgl55ETsK2", 3],
    ["5YjtErAXm2gIg1FinY8Chr7752G2", 66],
    ["ykkSIIZkLSNR3vWe5Xs0wLItr0m1", 49],
    ["IxdJCjckNbff2mJeXXS9wK9Hvj52", 8],
    ["j0pz8tOmQyXjINJh046rifcilJG3", 3],
    ["VUsuNd5PM6Xibly7cz0ehvs71Iv2", 52],
    ["7hxQMmlDFuRSj63iYQeiRA1ZG1M2", 71],
    ["fVHApibHybbBeyoH1NaE0J744Ty1", 61],
    ["dkZ4zAVyOHNFDSNPn6QOjgTmMk22", 38],
    ["jAjq13Oa2DOzfVVuEmcFIIbLtRR2", 35],
    ["egR3tXycu8SYWPtXTQusQYftjvA2", 73],
    ["DzlFpCr5QZRpVdgkqvfkbacNunw2", 68],
    ["mR48voietadubCGMtZBZGDL0Cui2", 59],
    ["hirTBUfkIQYN0BhpnjQh9r5ul5t1", 57],
    ["8O5vs50SxmeytGHvouA6d6QK6of1", 39],
    ["p9ETH8Uv8oQvY7h6wIBCTJRDF1p2", 23],
    ["pmoENhdtqEXBKz3LFzF8xAuNYov1", 54],
    ["wZ7YS4mqbtRN3F62bXTMq8wlJjj2", 42],
    ["wzqHQAbNV0OEt09vTm0NI7ijPUh2", 13],
    ["n6GW93bBEtYJc0dIlML16qFAvF43", 22],
    ["dy9tQ7ZwBAXyehPiymz95bS8jGu2", 81],
    ["HWURkYNSqpXqewNZiC0VjRg1k2V2", 12],
    ["zXNz7hWBOvSmJA1RgRRRLcg394A3", 69],
    ["4mAPydWE3Xg0mdn8FEmTvb3VILp2", 18],
    ["6dCWdUXuU9VJTiTKXy61nuLGtE02", 69],
    ["GaEYd1jPEnapw6SizQqwnc1XVRK2", 63],
    ["ZKufFKXJnyZ8U3IFKb8PbhHKBjz2", 14],
    ["zJQF8aq28pMr2HZ1DKiumsFN4zq2", 34],
    ["4dZx8QSYkvVEKnWlD7feNwVjlMh1", 30],
    ["Dc0xWkgzYpNI3p357cVHTdPNKm33", 38],
    ["IpUF0tyU8kRX538OxoTGZCjTcCG2", 8],
    ["l13sbsdOK9PxxnFUF4rYa1rer2E2", 55],
    ["BY12wcJXAaO566lvemRmazCXbU03", 49],
    ["vsg8bvPiGeZ5HBlfyGmOIUazVwE3", 72],
    ["UeajC59QEzZItLzfBTxBXiu6AkI3", 28],
    ["PeMjCORh3ONetO6M1JIbfs7sZJw2", 14],
    ["uRSGE5XPUSPmD5GEWDsJu2dxpt73", 40],
    ["h33fsa4aRMReW0hGPWmRbBFgA2j2", 43],
    ["QVbnMtXCunRtHNN8ZdFf4qXcSha2", 51],
    ["ZqDhmNagpFZqlz53zfD8dUjAhY13", 26],
    ["6zQrlJ35OVcXe5ZmgsZIPAk2ELC2", 53],
    ["aMP1uhE8KRd8cqn0gqnYkFJLvrn1", 42],
    ["v8mSPdNBXbMhbnqXDnMlBeMyc0l1", 6],
    ["wLBVcGHZUIfXrIxvlTNbOnCvQZ02", 67],
    ["XLCYJwpHGAY0pFB3iiVKEwkzxE83", 25],
    ["qTJewtM1Q7ftxREcoZuqP4Fmkdq2", 8],
    ["WnoYgpRT3pRgJxztWm2KABPKCJC3", 37],
    ["iaQVPDgOrwM8hNLtDW4ykJL5Oj22", 16],
    ["EU7OgONbXzeYJmo2ba0UjaaVn1J2", 25],
    ["svzyM1Ws9ubM1bTakMN6VaqbyM12", 16],
    ["dqukSsiHUEa3SHsZazI0sei4YNf1", 42],
    ["hJf1W1Wyi6VXz9ZP2OvQZyDMJVH3", 25],
    ["uZSfQU2g58PUkJpFWCC1XXadRPD2", 7],
    ["XHRJIomhJNet6zfRA7pIqj5jCqH3", 4],
    ["EfxLgcB39xatqosALwFXzdOfiEh2", 32],
    ["jHFgBGuXxwPLY3G2JBFGton0vnG3", 31],
    ["u6PkFYdEb4MFlHIS1xWBzVSW4yb2", 58],
    ["BkAl5qJx4kc7ZOHAOsao0MlWdBg2", 26],
    ["g5bbxvJaKPYvyCAdyXNneo77I9M2", 2],
    ["R2SgIL2Eg2ZNuyMncknl3zzRHIw1", 32],
    ["HvWNreLqddNvMDq6NzWPNXLF0zA2", 11],
    ["FrsWJnMlGXYMJQuqtjOX33hEoxd2", 49],
    ["jZL7oeAgTTSb7ZkxRuZKnhvsish1", 16],
    ["L2HQm3q5hxZUb0QOq6tnJn7FraW2", 52],
    ["3y8O1DwfB4NsgAQ9O3qbNrnm03s1", 62],
    ["o5RvR4fBOZUwktLrX7DekBqQ2AV2", 26],
    ["RHwPo7dIticGmmZtguIHJk4aABa2", 17],
    ["1usDyD2uXTf38rBgl4yHB02k8KR2", 23],
    ["ujMTNrnjAET3PqloysgtQVHwjJy1", 38],
    ["xjYAjhJGgCOoak5TrltFnTwaPfc2", 64],
    ["x8PYPlGbJiQiYpmWA548Tb6VvJl1", 42],
    ["xy5Dcd4jOTSZwtfWC6o4fY9b2Ku1", 26],
    ["P3Vvdc7B9YgdFcGGEdHGQASbmCu2", 32],
    ["yktIoxSZYgOq7hKNd20lUxdbW9q2", 57],
    ["VlOzipXLs8ctDxJfasVDiNWe9uH2", 43],
    ["EmDwpKyrfubWnqeXaydK0c1sDVp1", 23],
    ["JERv3M5QIXYnSsMRDYd8XsgmWan1", 11],
    ["MlBbBzBiKVZG0FjkexsvcqUEug23", 82],
    ["RoxmQZu58MOj34kOPmbg1lleYhh1", 35],
    ["dd6qjq96kwhiruiyVJGiBoIG2Zh2", 16],
    ["HgPhVtfx0IRifJ6KRyyKJkz2TIi1", 21],
    ["mN7a7ntULJY6tmBYbrBRGJErHTM2", 40],
    ["2rsD6WSCCHfXOIT0mE8HXHqX86O2", 26],
    ["u0FcrkzAL5T0v6YljSevBrqmTx43", 45],
    ["k6nYyMYZQURBW85Wtupw859CJ3K3", 23],
    ["Bj23pCyWO7XJDHD052cmbwyLNxG3", 35],
    ["MozGPlCzdVYuO5yytPcXfMlAidu1", 47],
    ["JC4gmcnLezYGxFhKAm93cR84voF3", 19],
    ["xj5iV8tJZBYlYRAn3w6ETxuvrX72", 33],
    ["uwr9LTKa5fU2OuSJcWppcdHrBOt1", 59],
    ["brKABk0oxUTsHmqMGMgL5AuOBAk2", 31],
    ["xaVoq9X8qGPJEFTcmrvwDX8fgvs1", 37],
    ["i3OaQ7sWbdhoI5vWALnONulfbsy2", 14],
    ["qGzVdg8TYvdu09byGBDf7VDwlZE2", 74],
    ["upZN2GCNjTR17fbnDelSFNxZcNU2", 34],
    ["O5FSYEcmwpa4I6qCOjg9ndm9VR82", 78],
    ["Md54fmjnzlhZyhs1RPAGF2UhHkE3", 11],
    ["nvA7Anjtqna8tVFFgKwEoSOykkb2", 19],
    ["fkFyGTIsCvQRRuvURrZbqzBOfzu1", 21],
    ["cUgc0WG028fdw3QMK9lgCCmXGce2", 35],
    ["nLrqNkfzSBUN9d9JeadG5etJcM63", 33],
    ["SGsl7GqTukfnZVBNUXAJxsVsnxK2", 65],
    ["UChDWRLWaacFzkXrmb3aRoJBVt03", 42],
    ["jziiH5V0upbMrgyMtFPFcLLfy1l1", 70],
    ["CyI2xOlGlYhCL9bPP2zdHWj4c8b2", 8],
    ["xfpMs7wYlkbdplhmwuR7J7jwRoj2", 30],
    ["NjOxWbOz6LgjYsYax4pvZhBUheS2", 77],
    ["2XCzBJrvsjXykFfp1oyM4mbdwny2", 9],
    ["9dny5rPoYTbBbuQTet1AC54x7fw2", 6],
    ["WS5sXkgQChOG3DC9HaQHQTuDubO2", 1],
    ["UjZdBkJyMGe9jQcdMQ25fsNhlfF3", 32],
    ["zI57U8Fn4EewxrDz9vT2eABJXSm2", 18],
    ["tY2kMnLGwQcBda7oBgReSsPC84P2", 7],
    ["IIM0zq6SQxclNusqEdGzVOWhN7o2", 45],
    ["PR0CvPsP68NNr9C9VNoyu5kG3e93", 4],
    ["MFIflcSwbqTtSFGiMWlhkYCQKkJ3", 21],
    ["nIkIoIW3kkWsPri40FRQDj4ixCR2", 38],
    ["7Hxpfb24s6Ua2HZYC4f4gy3SEQC3", 67],
    ["cvUOE21lFMd42qaGqk4q5znWn4u1", 30],
    ["KNB87oL5qaY0UeDXOrhLZ2edC0z2", 33],
    ["K8k12PAUr5dYaXTcfxEijPG12RU2", 9],
    ["dAFz9c9WeiZHOqkiuC2kNp41nPT2", 31],
    ["BcIAiMZim8coGsVbjaaf7HFJnRP2", 1],
    ["OZsUoEEsfNWQRo00hwqlu4n3s893", 50],
    ["I6JBLNqP7aY2NObRYEkBi2CjU9G2", 56],
    ["WmRkHrtxeYZnrJpfZ4dh3tvoBO22", 42],
    ["Jdt3i40ueFeL7OIEtOXD3mVgEVR2", 35],
    ["47NMpuXp8TeFsMZvjxHe3A5GO9x1", 60],
    ["sEDfnSL2tcUwimNBC8kk6xDuMAh2", 55],
    ["F5sDk7EaP9VqfYWqKFV4e2KOPey1", 50],
    ["GZHnA1jcoRM2XnyB3Ps5IMgQdNG3", 8],
    ["7hCbWJcWiRXT5Lz0Ed7CaNsYOZL2", 46],
    ["zNGdWXHF84UhjLjrAM9VPx1p7Mz1", 7],
    ["1qicBPSaY4O4YBwZXtZczdrXgFd2", 1],
    ["fFAoUkSF9LchC2lbatsBX72n16N2", 36],
    ["pnJTLFppq5Ulp6ujjui3oMNtTok1", 39],
    ["iuDutUPKZSdDaS3RpAA4qM7vQLC2", 12],
    ["wojP5o2ZJvcUW4uwQMqrTT3Q4BE3", 3],
    ["cX2A0XPWAea7CHQC2Ptuw91IiLd2", 23],
    ["YgnujrtoTqNpThFlEwLxCKp6jZw1", 19],
    ["UbMqPFIl7cRFhv6QGNb5UgZMYdp2", 15],
    ["WVhBP9TZw4ac5XJOwR1NrUdDCkl1", 29],
    ["rNUehD0i0VYns2j32uBF7hzeXBx1", 6],
    ["uILD1p3Ya4OToJX97prJOeNSyZZ2", 27],
    ["r7yR56QuJCX9EPuS2XnhV7lyTJd2", 33],
    ["dMrdCHWUwaZZqqPfzXptJeJMLSn1", 10],
    ["ZG5hR3HTRNeH81Mdu4TuFu04O0D2", 22],
    ["SlH68P1bZNfujfJQer5wS0VWeWo2", 12],
    ["fUe7rU3sE1ZxvpYiVlsl5XrdFB92", 42],
    ["aFflj09KvrQ4OsN4ELR495N6gun2", 5],
    ["u3mt9eWobbhCTs3dAg98coXpkfH3", 63],
    ["xfZhMiixGyW35uFwt8zjB8Tj2Lw1", 13],
    ["PTRPCIkZQaf7N3j0TPyT8B8u19e2", 8],
    ["vciDMFjj76blmGHN1QvkbtktzqO2", 39],
    ["5pel43lRMlTPDMN0NxdpWbubkqz1", 18],
    ["eQas9O7iLbUCGZOqmHaBm0kyhut1", 17],
    ["pD66HO0rDpNKEnqsqgTIhc2sFgj1", 23],
    ["LwzBRzIxtFU07Kayfn7XKhapD9P2", 58],
    ["JVYYIsSIyhR7poJPE2cqfLnVmIz1", 41],
    ["08ynAWi89sfHeDINFcTa3byWFI73", 50],
    ["trz1o7b2MdQq1uLiYTHvW6erHxD2", 24],
    ["XK4AKgrUgAff7Jd58bEsnhwGthL2", 52],
    ["kPS2Dxu05DVRBlleVYdgqvgpvIT2", 20],
    ["qaeVBCdM6PVyxrDxPfhTEIW6pFj1", 4],
    ["Q7CkUqFLuOdT8GhaNyTjxcz6Chi1", 11],
    ["Z60Sk4nJdkfSTz9UQcXbVUVqcVD2", 17],
    ["fbTBmHYhJKZh0cnSJDlK2i8Tjs22", 30],
    ["nfWHnQNg06WKbqDSm7YfWJ63hFq1", 23],
    ["1cIdhhEZCmQ6wjzaUHw6EHuPxXq2", 39],
    ["fhTTkW4qK2R1og6zY4u6PEZBEB03", 45],
    ["JasxRGotErMonHBIrWrvkZM32su1", 34],
    ["fS0vn0H4ouUgv8lsK0oel16HEzG3", 14],
    ["mEU3M3IOnLQ12U5DR5WrFCJLAdB2", 30],
    ["o2lnlhJ0SxWk9Q6ZvFwyo9FuRLK2", 18],
    ["d9blFDYZvyfyIc6mEnDQftWxTJu1", 46],
    ["vWEvDKlvECTJ7ZCTKWFjnXfesiD3", 13],
    ["mYY3yZoX4yahShFq8EbuUxoaHnJ3", 20],
    ["BTrUfHyR34Tp7ylbpE5HGRMj7Bw2", 58],
    ["mkoh7Cia3fOqv7ZUnkmQsfHYNxF3", 44],
    ["SsQDoJ6EEZP0inJ2hhkRAFbyjD13", 54],
    ["h32za066OgSLRtu3woT5iswVnuO2", 36],
    ["YLMztUDOLRVZ5Zymc5C4zV2Af4N2", 43],
    ["fhvLqnVCukcT7ENqCG1OFGxOzAh1", 29],
    ["0djRFRrC6AcRUHKE5PRHEGpDBzm2", 75],
    ["r2qsHZpNF8WRpqOcnJfS5w5RZKr2", 66],
    ["ufS6a7QcKQVyYlneFTgILk3hH483", 47],
    ["MLrewomoEYfzoxjTOpkOsniAuRf1", 42],
    ["l7GZPxYcWIgRjNBITVLhC9q70lM2", 9],
    ["eeikVQXUPJfPiEymHSWVLgJF3gY2", 18],
    ["awXmOTj9TlRwPfSwu0QMrkjWTAd2", 32],
    ["jyAiKopceWeOhCUbfRQihFeXXOy1", 52],
    ["b1AA9nXUspgtVCcykjkt36AHoLp1", 4],
    ["E64Uh4LDQKSCNZ0E0zohzBZNdED2", 21],
    ["WHNv3r3Ngsh9OX3OMPLVbtSXlaZ2", 52],
    ["LvO0SPG3tbTbk7evxjTwBIbhjKk1", 52],
    ["O49fGxJwqXSaoW0FBmN9gk4Q0av1", 23],
    ["28CkRdNmhvTZpT508XQfLrgtwFb2", 52],
    ["w6SXsMUKBFQD8RzXoGN6Lmuzcek1", 52],
    ["AWERqB2Ov8U1jn65pk3SgcSkOeR2", 24],
    ["lKjCwZ7KRTYeCCm8nwlwQQBDfkD3", 35],
    ["SOSXHzdgrdU1fTavWwdlepMBBWi1", 3],
    ["DhXTIlzMILMdlir3SQTOG9R5VYM2", 59],
    ["pncCWmCc3QTAnTXD1FPDG4vVoJu1", 47],
    ["Q1DtfhPAB4WfSI72V35ZAlRVNGk1", 6],
    ["Ir40HWrLnadO4f8GdhccQXQtlMs1", 30],
    ["kqSCtIPzi4XkxZ8GyS5Bo1MnohU2", 35],
    ["pMj5src7xBMQ2qD1ND9WvgtnOpD3", 16],
    ["UT9yFSElRtbZMq8EfZHaYtja83D2", 83],
    ["R033jFq7mePztFYOEpDBisy8KRr1", 34],
    ["wv0BFdBX8hTOW6TvN1EYDU8vWhH3", 42],
    ["zYFWPx9tFlZLugKLtUsrJHIXgxi1", 41],
    ["TAGT6CWBmjgPJCNGM7xATa6oR4N2", 54],
  ];

  let i = 1;
  for (const user of users) {
    const [uid, final_derecesi] = user;
    const data = {
      products: {
        oyun2024: {
          final_derecesi,
        },
      },
    };
    await saveUserData(uid, data);
    if (i % 10 === 0) console.log(i + "/" + users.length);
    i++;
  }
};

const getElemeSuccessfulEmails = async () => {
  const allUsers = await getAllUsers();
  const emails = [];
  for (const user of allUsers) {
    const { email, products } = user;
    if (products?.oyun2023?.eleme_sonuc === 1) {
      emails.push(email);
    }
  }
  console.log(emails);
  arrayToCsv(emails, "eleme_successful_emails.csv");
};

//Eleme sonucunda yarı finale hak kazananların listesi
export const getElemeSuccessfulEmailsYf = async () => {
  const allUsers = await getAllUsers();
  const emails = [];

  for (const user of allUsers) {
    const { email, products } = user;
    if (products?.oyun2024?.eleme_sonuc === 1) {
      emails.push(email);
    }
  }
  const csvContent = "data:text/csv;charset=utf-8," + emails.join("\n");
  const encodedUri = encodeURI(csvContent);
  const link = document.createElement("a");
  link.setAttribute("href", encodedUri);
  link.setAttribute("download", "eleme_successful_emails.csv");
  document.body.appendChild(link);

  link.click();
};
//yarı final sonucunda finale kalanlar
export const getYfSuccessfulEmailsFn = async () => {
  const allUsers = await getAllUsers();
  const names = [];

  for (const user of allUsers) {
    const { name, products } = user;
    if (products?.oyun2024?.yf_sonuc === 1) {
      names.push(name);
    }
  }

  const bom = "\uFEFF"; // UTF-8 BOM
  const csvContent = bom + names.join("\n");
  const encodedUri = encodeURI("data:text/csv;charset=utf-8," + csvContent);
  const link = document.createElement("a");
  link.setAttribute("href", encodedUri);
  link.setAttribute("download", "Finalist Listesi.csv");
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const getTopRankHolders = async (season) => {
  const queryOfRanks = query(
    collection(db, "users"),
    where(`products.${season}.final_derecesi`, "<=", 10)
  );
  const querySnapshot = await getDocs(queryOfRanks);
  let allUsers = [];
  querySnapshot.forEach((doc) => {
    // doc.data() is never undefined for query doc snapshots
    const user = doc.data();
    allUsers.push({
      name: user.name,
      birth_year: user.birth_year,
      medal: user.products[season].final_derecesi,
      uid: doc.id,
    });
  });
  return allUsers;
};

export const generateHallOfFames = async (season) => {
  const hallOfFameUsers = await getHallOfFameUsers(season);
  const topRankedUsers = await getTopRankHolders(season);

  for (const user of topRankedUsers) {
    const { uid } = user;
    const isUserInHOF = hallOfFameUsers.find((u) => u.uid === uid);
    if (!isUserInHOF) {
      const ref = doc(collection(db, "hall_of_fames"));

      await setDoc(
        ref,
        {
          user_id: uid,
          season: season,
          name: user.name,
          category: generateCategory(user.birth_year),
          medal: user.medal,
          result: `${generateCategory(user.birth_year)} ${STATUS[user.medal]}`,
        },
        { merge: true }
      );
    }
  }
};

export const getHallOfFameUsers = async (season) => {
  if (!season) {
    console.log("Please provide a season name to get hall of fame users.");
    return [];
  }
  const queryOfHOF = query(
    collection(db, "hall_of_fames"),
    where("season", "==", season)
  );
  const querySnapshot = await getDocs(queryOfHOF);
  let allUHOFusers = [];
  querySnapshot.forEach((doc) => {
    allUHOFusers.push(doc.data());
  });
  return allUHOFusers;
};
export const uploadImageToDb = async (file, uid) => {
  const storageRef = ref(
    storage,
    `2024_public/hall_of_fame/${uid}/profile.jpg`
  );
  const snapshot = await uploadString(storageRef, file, "base64");
  const starsRef = ref(storage, snapshot.ref.fullPath);
  const downloadURL = await getDownloadURL(starsRef);
  return downloadURL;
};

export const addAboutToDb = async (about, season, uid, photoURL) => {
  const queryOfHof = query(
    collection(db, "hall_of_fames"),
    where("season", "==", season),
    where("user_id", "==", uid)
  );
  const querySnapshot = await getDocs(queryOfHof);
  let hofDoc = querySnapshot?.docs?.[0];
  if (!hofDoc) {
    alert("Kullanıcı bulunamadı.");
    return;
  }
  let trimmedAboutString = about.slice(0, 100);
  trimmedAboutString = trimmedAboutString + "...";
  const ref = doc(db, "hall_of_fames", hofDoc.id);
  await setDoc(
    ref,
    {
      about: trimmedAboutString,
      about2: about,
      photo: photoURL,
    },
    { merge: true }
  );
};

export const getHallOfFameUser = async (season, uid) => {
  const queryOfHof = query(
    collection(db, "hall_of_fames"),
    where("season", "==", season),
    where("user_id", "==", uid)
  );
  const querySnapshot = await getDocs(queryOfHof);
  let hofDoc = querySnapshot?.docs?.[0];
  if (!hofDoc) {
    alert("Kullanıcı bulunamadı.");
    return;
  }
  return hofDoc.data();
};

export const writeTokenToDb = async (uid, token) => {
  const ref = doc(db, "users", uid);
  await setDoc(
    ref,
    {
      token_for_concurrent_logins: token,
    },
    { merge: true }
  );
};

export const getConcurrentLoginToken = async (uid) => {
  const ref = doc(db, "users", uid);
  const docSnap = await getDoc(ref);

  if (docSnap.exists()) {
    return docSnap.data().token_for_concurrent_logins;
  } else {
    // doc.data() will be undefined in this case
    console.log("No such document!");
  }
};
